import { Govern } from "../../assets/image";

export const Loader = () => {
  return (
    <div id="loading-overlay">
      <div className="spinner">
        <Govern />
      </div>
    </div>
  );
};
